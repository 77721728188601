import React from "react";
import '../css/carousel.css';

const Carousel = ()  => {

    return (
        <div>
            <body>
    <div class="glass">
        <img src="/product/shirt/waisting-time-is-infinite-front.webp"/>
        <p>
        Can't Buy It Here
        <h5>
            <a href="https://www.ebay.com/itm/315676557299?itmmeta=01J6JZ6GN8N2CAE4MMCVG4K43A&hash=item497fc9fff3:g:YPkAAOSw2GVmwTNh">Buy It Here --></a>
         </h5>
        </p>
    </div>
</body>
        </div>
    )
}
export default Carousel;